<template>
  <div>代理权限设置</div>
</template>

<script>
export default {

}
</script>

<style>

</style>